
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer},
      name: 'PricingPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data() {
        return {
          amount: 0,
          result: 0,
          fee: 2.9853,
        };
      },
      methods: {
        feemath() {
          if (Number(this.amount)) {
            let math: any;
            let language = localStorage.getItem('locale') || 'th';
            let text = language === 'th' ? ' บาท' : ' Baht';
            let res: any = 0;
            math = (Number(this.amount) / Number(100)) * Number(this.fee);
            res = parseFloat(math).toFixed(4) + text;
            this.result = res;
          } else {
            this.amount = 0;
            this.result = 0;
          }
        },
      },
      watch: {
          locale() {
            this.feemath();
          },
      },
    }
);
